import type { specialMfoRoutes, whiteLabelDeniedListRoutes } from '@src/shared/constants/pageRoutes';
import {
  appWhiteLabelDeniedRoute,
  kazakhstanSeoListRoutes,
  kzContextPageRoute,
  partner2GisPageRoute,
  specOffersLandingPageRouteBistrodengi,
  specOffersLandingPageRouteTurbozaim,
  specOffersLandingPageRouteWebbankirWb1,
  specOffersLandingPageRouteWebbankirWb2,
  t2WhiteLabelDeniedRoute,
  webWhiteLabelDeniedRoute,
} from '@src/shared/constants/pageRoutes';

type BasePath = (typeof specialMfoRoutes)[number] & typeof whiteLabelDeniedListRoutes;

const kazakhstanAffIdByRoute = [...kazakhstanSeoListRoutes, kzContextPageRoute].reduce((acc, route) => {
  acc[route] = '12024';
  return acc;
}, {});

export const DEFAULT_AFF_ID_VALUE = '2';

export const affIdByBasePath: Record<BasePath, string> = {
  [specOffersLandingPageRouteWebbankirWb1]: '6184',
  [specOffersLandingPageRouteWebbankirWb2]: '6185',
  [specOffersLandingPageRouteBistrodengi]: '6186',
  [specOffersLandingPageRouteTurbozaim]: '6187',
  [webWhiteLabelDeniedRoute]: '12181',
  [appWhiteLabelDeniedRoute]: '12181',
  [partner2GisPageRoute]: '12121',
  [t2WhiteLabelDeniedRoute]: '36968',

  ...kazakhstanAffIdByRoute,
};
